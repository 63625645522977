<template>
  <img :src='img' />
</template>

<script>

export default {
  name: 'Mana',
  props: {
    color: String
  }, 
  mounted() { },
  methods: { },
  computed: { 
    img() {
      return `/images/mana/${this.color}.svg`
    }
  },
  watch: { }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang='scss'>
  img {
    width: 16px;
    height: 16px;
    margin: 2px;
  }

  // span:before{
  //   display: inline-block;
  //   font-family: 'MTG';
  //   font-style: normal;
  //   font-weight: normal;
  //   line-height: 1;
  //   -webkit-font-smoothing: antialiased;
  //   -moz-osx-font-smoothing: grayscale;
  //   font-size: 1.5em;
  // }
  // .B:before{@extend .white, .black-text; content:'\0042'; border-radius: 50%; }
  // .G:before{@extend .white, .green-text; content:'\0047'; border-radius: 50%; }
  // .R:before{@extend .black, .red-text; content:'\0052'; border-radius: 50%; }
  // .U:before{@extend .white, .blue-text; content:'\0055'; border-radius: 50%; }
  // .W:before{@extend .black, .white-text; content:'\0057'; border-radius: 50%; }

  // .small {
  //   font-size: 1em;
  // }
</style>



