<template>

  <nav>
    <div class='nav-wrapper'>
      <router-link class='breadcrumb' to='/'>
        <i class='material-icons'>home</i>
      </router-link>
      <slot name='parent' /> 
      <ul class='right'>
        <slot name='actions'>
          
        </slot>
      </ul>
    </div>
  </nav>

</template>

<script>
export default {
  created () {
  },
  name: 'Breadcrumb',
  props: {
    title: String
  },
  components: {

  },
  mounted: function() { }, 
  watch: { },
  methods: { }, 
  computed: { }
}
</script>

<style scoped lang="scss">
  nav {
    @extend .green, .darken-2;
    padding-left: 10px;

    ul {
      // padding-right: 10px;
    }
  }
</style>
