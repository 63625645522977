<template>
  <div id='home'>
    <div class='row'>
      <div class='col s12'>
        <div class='card'>
          <div class='card-content'>
            <span class='card-title'>Introduction</span>
            <p class='flow-text justify'>
              VerdantForce is an unofficial companion application to help track the countless bits of data needed for a fun and successful game of Magic.
            </p>
          </div>
        </div>
      </div>
      <div class='col s12'>
        <div class='card'>
          <div class='card-content'>
            <span class='card-title'>Early Access</span>
            <p class='flow-text justify'>
              We're just getting started around here so things may looks a little barren.  Check out the current features below and stay tuned for more!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home',
  components: {

  },
  data: function() {
    return { }
  },
  mounted: function() {

  }, 
  watch: {  

  },
  methods: {

  }, 
  computed: { }
}
</script>

<style scoped lang="scss">
  html {
    @extend .grey, .darken-1;

    -webkit-tap-highlight-color: transparent;
  }

  div#home {
    padding-bottom: 50px;
  }
</style>
