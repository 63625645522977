import Vue from 'vue'

import App from './App.vue'
window.M = require('materialize-css')

import './registerServiceWorker'
import router from './router'

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    toast(message, displayLength = 1500, outDuration = 300) {
      window.M.toast({ html: message, outDuration:  outDuration, displayLength: displayLength })
    }
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

const isPWA = window.matchMedia('(display-mode: standalone)').matches ||
              window.navigator.standalone === true

const minWidth = 400

if (isPWA) {

  if (!localStorage.windowSize) {
    window.resizeTo(minWidth, 600)
  }

  window.addEventListener('resize', () => {
    if (window.outerWidth < minWidth) {
      window.resizeTo(minWidth, window.outerHeight)
    } 
    localStorage.windowSize = JSON.stringify({ width: window.outerWidth, height: window.outerHeight })
  })
}