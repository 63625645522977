<template>
  <div>
    <Collection title='Counters' add='true' v-on:create="create">
      <Counter v-on:remove='remove'
        v-for="(counter, key) in counters" 
        :key="key" 
      />
    </Collection>
  </div>
</template>

<script>
import Collection from './components/Collection.vue'
import Counter from './components/Counter.vue'

export default {
  name: 'Counters',
  components: {
    Collection,
    Counter
  },
  data: function() {
    return {
      counters: {}
    }
  },
  mounted: function() {
    if (localStorage.counters) {
      this.counters = JSON.parse(localStorage.counters)
    } else {
      localStorage.counters = JSON.stringify(this.counters)
    }
  }, 
  watch: {  
    counters() {
      localStorage.counters = JSON.stringify(this.counters)
    }
  },
  methods: {
    create: function() {
      let timestamp = Date.now()
      let counterName = prompt('Counter Name', `New Counter ${Object.values(this.counters).length + 1}`)
      
      if (counterName) {
        this.counters[timestamp] = { title: counterName, value: 0 }
        this.toast(`${counterName} created.`)
      }
      this.counters = Object.assign({}, this.counters)
    },
    remove: function(id) {
      let counter = this.counters[id]
      if (confirm(`Are you sure you want to delete ${counter.title}?`)) {
        
        this.$delete(this.counters, id)
        this.toast(` ${counter.title} deleted.`)
      }
    }
  }, 
  computed: {

  }
}
</script>

<style scoped lang="scss">

</style>
