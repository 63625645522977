<template>
  <ul class='collection' :class="{ 'with-header': header, avatar: avatar}">
    <li v-if='header' class='collection-header'>
      {{ header }}
      <span class='actions'>
        <slot name='actions' />
      </span>
    </li>
    <li class='collection-item' :class="{ avatar: avatar }" v-for="(item, key) in items" :key="key"  v-on:click="$emit('click', key)">
      <slot name="item" :item="item" />
    </li>
  </ul>
</template>

<script>

export default {
  name: 'Collection',
  props: {
    items: Object,
    avatar: Boolean, 
    header: String
  }, 

  data: function() {
    return {  }
  },
  mounted() { },
  methods:  { 
    alert(msg) { console.log(msg) }
  },
  computed: { },
  watch:    { }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss'>
  .collection {
    margin-top: 0px !important;

    border: unset !important;

    &.with-header {
      li.collection-header {
        @extend .green, .darken-2, .white-text;
        font-weight: bold;
        padding-left: 15px;
        padding-right: 15px;

        span.actions {
          @extend .right;

          i.material-icons {
            @extend .white-text;
            font-size: 20px;
            cursor: pointer;
            margin-left: 10px;
          }
        }
      }
    }

    li.collection-item {
      padding-left: 15px !important;
      padding-right: 15px !important;

      cursor: pointer;

      span.badge {
        // line-height:1px;
      }

      &.avatar {
        min-height: 64px;


        .circle {
          left: 10px !important;
        }
      }
    }
  }
</style>
