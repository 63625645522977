<template>
  <div>
    <div v-if='small'>
      <i class='material-icons tiny plus' v-on:click.stop="change(value + 1)">add_circle</i>
      <i class='material-icons tiny minus' v-on:click.stop="change(value - 1)">remove_circle</i>
      <span class='badge' v-on:click.stop="input" v-bind:data-badge-caption="value"></span>
    </div>
    <div v-else>
      <i class='plus material-icons' v-on:click="change(value + 1)">expand_less</i>
      <h3 v-on:click.stop="input">
        {{ value }}
      </h3>
      <i class='minus material-icons' v-on:click="change(value - 1)">expand_more</i>
    </div>
  </div>
</template>

<script>
import { evaluate } from 'mathjs'
export default {
  name: 'NumberPicker',
  props: ['title', 'min', 'value', 'small'], 

  data: function() {
    return { }
  },
  methods: {
    change(value) {
      let evaluated = evaluate(value)
      this.$emit('update:value', evaluated <= this.minimum ? this.minimum : evaluated)
    },
    input() {
      let newValue = prompt(this.title, this.value)
      if (newValue) {
        this.change(newValue)
      }
    }
  },
  computed: {
    minimum() {
      return parseInt(this.min)
    }
  },
  mounted() {
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang='scss'>
  
  h3 {
    margin-top: 1.1rem;
    margin-bottom: 1.5rem;
  }

  i {
    @extend .grey-text, .text-lighten-2;
    cursor: pointer;
  }

  i.minus:active {
    @extend .red-text;
  }

  i.plus:active {
    @extend .green-text;
  }    

  i.tiny {
    font-size: 1.3rem;
    line-height: 1.3rem;
  }

  .badge {
    @extend .new, .green, .darken-3;
  }
</style>
