import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Decks from '../views/Decks.vue'
import Deck from '../views/Deck.vue'
import Counters from '../views/Counters.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/decks',
    name: 'Decks',
    component: Decks
  },
  {
    path: '/deck/:id',
    name: 'Deck',
    component: Deck
  },
  {
    path: '/counters',
    name: 'Counters',
    component: Counters
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
