<template>
  <div>
    <ul class="collapsible collection" ref='collapse'>
      <li class='collection-heading'>
        <span class='collection-title'>{{ title }}</span>
      </li>
      <slot></slot>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'Collection',
  props: ['title', 'add'], 

  data: function() {
    return {  }
  },
  methods: {

  },
  computed: {
    hasAdd() {
      return this.add === 'true';
    }
  },
  mounted() {
    window.M.Collapsible.init(this.$refs.collapse, {})
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss'>
  ul.collapsible {
    border: 0;
    margin-top: 0px;

    &.collection {
      li.collection-heading {
        @extend .collection-item, .green, .darken-2, .white-text;
        padding-left: 12px;
        font-weight: bold;
        height: 49px;
        width: 100%;

        .collection-title {
          line-height: 28px;
        }

        i.secondary-content {
          @extend .white-text;
          margin-right: -5px;
          cursor: pointer;
          line-height: 30px;
        } 
      }
    }
    .collapsible-header {
      padding-bottom: 10px;
      height: 48px;


  
      .header-container {
        width: 100%;

        .collapsible-title {
          @extend .truncate;
          max-width: 50%;
        }
      }
    }

    .collapsible-body {
      @extend .white;

      padding: 0;
      padding-top: 10px;

      h3 {
        margin-top: 1.1rem;
        margin-bottom: 1.5rem;
      }

      i {
        @extend .grey-text, .text-lighten-2;
        cursor: pointer;
      }
    }    
  }

  .badge {
    &.winrate {
      @extend .new, .green, .darken-3;
    }
  }
</style>
