<template>
  <div id='deck'>
    <Breadcrumb :title='title'>
      <template v-slot:parent>
        <router-link class='breadcrumb' to='/decks'>Decks</router-link>
      </template>
      <template v-slot:actions>
        <li>
          <a :href="downloadURL" :download='downloadName'>
            <i class='material-icons'>cloud_download</i>
          </a>
        </li>        
      </template>

    </Breadcrumb>
    <div class='card'>
      <div class='card-content'>
        <div class='card-title'>
          {{ title }}
          <div class='secondary-content'>
            <span class='badge green white-text' data-badge-caption="">{{ winrate }}%</span>
            <Mana v-for='color in colors' :key='color' :color='color' />
          </div>
        </div>
        <div class='row'>
          <div class='col s6 center-align'>
            <h5>Won</h5>
            <NumberPicker title='Wins' :value.sync='win' min='0' />
          </div>
          <div class='col s6 center-align'>
            <h5>Lost</h5>
            <NumberPicker title='Losses' :value.sync='loss' min='0' />
          </div>
        </div>
      </div>
      <div class='card-action'>
        <a v-on:click='rename' class='btn-flat'>Rename</a>
        <a v-on:click='remove' class='btn-flat white right red-text'>DELETE</a>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from './components/materialize/Breadcrumb.vue'
import Mana from './components/Mana.vue'
import NumberPicker from './components/NumberPicker.vue'
export default {
  created () {
  },
  name: 'Decks',
  components: {
    Breadcrumb,
    Mana,
    NumberPicker
  },
  data: function() {
    return JSON.parse(localStorage.decks)[this.$route.params.id]
  },
  mounted: function() {

  }, 
  watch: {  
   win: { handler: 'store' },
   loss: { handler: 'store' },
   title: { handler: 'store' },
  },
  methods: {
    store() {
      let storage = JSON.parse(localStorage.decks)
      storage[this.id] = this.$data
      localStorage.decks = JSON.stringify(storage)
    },
    rename() {
      let newName = prompt('Rename Deck', this.title)
      if (newName) {
        this.title = newName
        this.toast(`Deck renamed.`)
      }
    },
    remove: function() {
      if (confirm(`Are you sure you want to delete this deck?`)) {
        let storage = JSON.parse(localStorage.decks)
        delete storage[this.id]
        localStorage.decks = JSON.stringify(storage)
        this.toast('Deck deleted.')
        this.$router.push({ name: 'Decks' })
      }
    },
  }, 
  computed: {
    id() {
      return this.$route.params.id
    },
    winrate() {
      let total = this.win + this.loss
      if(total <= 0) return 100

      return ((this.win / total) * 100).toFixed()
    },
    downloadURL() {
      let blob = new Blob([JSON.stringify(this.$data)], { type: 'application/json' })
      return URL.createObjectURL(blob)
    },
    downloadName() {
      return `${this.title}.json`
    }
  }
}
</script>

<style scoped lang="scss">
  .card {
    margin-top: 0px;

    .card-content {
      .card-title {
        .badge {
          // margin-top: 5px;
          &::after {
            content: '' !important;
          }
          @extend .new, .green, .darken-3, .white-text;
          font-size: 1em !important;
          line-height: 1.25em;
          height: 1.25em;
        }
      }
    }
  }

  
</style>
