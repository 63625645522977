<template>
  <li>
    <div class='collapsible-header valign-wrapper'>
      <div class='header-container'>
        <div class='right'>
          <NumberPicker :title.sync='title' :value.sync='value' min='0' small='true'/>
        </div>
        <span class='collapsible-title'>{{ title }}</span>
      </div>
    </div>
    <div class='collapsible-body'>
      <div class='row'>
        <div class='col s12'>
          <a v-on:click='rename' class='btn-flat'>Rename</a>
          <a v-on:click='remove' class='btn-flat white right red-text'>DELETE</a>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import NumberPicker from './NumberPicker.vue'

export default {
  name: 'Counter',
  props: ['id'], 
  components: {
    NumberPicker
  },
  data: function() {
    return { value: 0, title: '', dataFields: ['value', 'title'] }
  },
  methods: {
    rename() {
      let currentName = this.title
      let newName = prompt('Rename Counter', currentName)
      if (newName)
        this.title = newName
        this.toast(`${currentName} renamed to ${newName}`)
    },
    remove: function() {
      this.$emit('remove', this.$vnode.key)
    },
    readStorage(key) {
      let storage = JSON.parse(localStorage.counters)

      if (storage[this.$vnode.key]) {
        this[key] = storage[this.$vnode.key][key]
      }
    },
    writeStorage(key, value) {
      let storage = JSON.parse(localStorage.counters)
      storage[this.$vnode.key][key] = value
      localStorage.counters = JSON.stringify(storage)
    }
  },
  computed: {
    stored: function() {
      let storage = JSON.parse(localStorage.counters)
      if (storage[this.$vnode.key])
        return storage[this.$vnode.key]
      else
        return {}
    }
  },
  mounted() {
    this.dataFields.forEach(field => this.readStorage(field))
    
  },
  watch: {
    title: function(value) {
      this.writeStorage('title', value)
    },
    value: function(value) {
      this.writeStorage('value', value)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang='scss'>
  .collapsible-header {
    .header-container {
      .collapsible-title {
        max-width: 60% !important;
      }
    }
  }v

  .collapsible-body {
    padding-top: 20px !important;
  }
</style>
