<template>
  <div id='decks'>
    <Breadcrumb title="Decks">
      <template v-slot:parent>
        <router-link class='breadcrumb' to='/decks'>Decks</router-link>
      </template>

      <template v-slot:actions>
        <li>
          <a v-on:click="upload">
            <i class='material-icons'>cloud_upload</i>
          </a>
        </li>
        <li>
          <a v-on:click='create'>
            <i class='material-icons'>add_circle</i>
          </a>
        </li>
      </template>
    </Breadcrumb>
    <Collection :items="decks" v-on:click="open">
      <template v-slot:item="{ item }" >
        <span class='badge green white-text'>{{ winrate(item) }}%</span>
        <span class='title'>{{ item.title }}</span>
        <div class='secondary-content'>
          <Mana v-for='color in item.colors' :key='color' :color='color' />
        </div>
      </template>      
    </Collection>

  </div>
</template>

<script>
import Breadcrumb from './components/materialize/Breadcrumb.vue'
import Collection from './components/materialize/Collection.vue'
import Mana from './components/Mana.vue'
// import Deck from './components/Deck.vue'

export default {
  created () {
  },
  name: 'Decks',
  components: {
    Breadcrumb,
    Collection,
    Mana
  },
  data: function() {
    return {
      decks: {}
    }
  },
  mounted: function() {
    if (localStorage.decks) {
      this.decks = JSON.parse(localStorage.decks)
    } else {
      localStorage.decks = JSON.stringify(this.decks)
    }
  }, 
  watch: {  
    decks() {
      localStorage.decks = JSON.stringify(this.decks)
    }
  },
  methods: {
    create: function() {
      let timestamp = Date.now()
      let deckName = prompt('Deck Name', `New Deck ${Object.values(this.decks).length + 1}`)
      
      if (deckName) {
        this.decks[timestamp] = { title: deckName, win: 0, loss: 0, colors: [] }
        
        this.decks = Object.assign({}, this.decks)
        localStorage.decks = JSON.stringify(this.decks)
        
        
        this.toast(`${deckName} created.`)
        this.$router.push({ name: 'Deck', params: { id: timestamp }})
      }
    },
    remove: function(id) {
      let deck = this.decks[id]
      if (confirm(`Are you sure you want to delete this ${deck.title}?`)) {
        this.$delete(this.decks, id)
        this.toast(`${deck.title} deleted.`)
      }
    },
    open(key) {
      this.$router.push({ name: 'Deck', params: { id: key }})
    },
    upload() {
      let input = document.createElement('input')
      input.type = 'file'

      input.onchange = e => {
        let file = e.target.files[0]
        let reader = new FileReader()
        reader.readAsText(file, 'UTF-8')
        reader.onload = r => {
          let deck = {}
          let timestamp = Date.now()
          deck[timestamp] = JSON.parse(r.target.result)

          // this.decks = Object.assign(this.decks, deck)
          localStorage.decks = JSON.stringify(Object.assign(this.decks, deck))
          this.decks = JSON.parse(localStorage.decks)
        }
      }

      // if (Object.values(this.decks).length == 0 || confirm('This will clear existing decks.  Proceed?'))
      input.click()
    },
    winrate(deck) {
      let total = deck.win + deck.loss
      if(total <= 0) return 100


      return ((deck.win / total) * 100).toFixed()
    }
  }, 
  computed: {
    download() {
      let blob = new Blob([localStorage.decks], { type: 'application/json' })
      return URL.createObjectURL(blob)
    }

  }
}
</script>

<style scoped lang="scss">
  .badge {
    @extend .new, .green, .darken-3, .white-text;

    &::after {
      content: '' !important;
    }
    // border: 1px solid black;
    // font-weight: bold;
    // font-size: 0.8em !important;
  }
</style>
