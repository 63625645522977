<template>
  <div id='app'>
    <div class='navbar-fixed'>
      <nav>
        <div class="nav-wrapper">
          <router-link to="/">
            <span class="brand-logo">VerdantForce</span>
          </router-link>
        </div>
      </nav>
    </div>
    <router-view id='main'/>
    <nav class='footer'>
      <ul class='row'>
        <router-link class='col s6 center-align' to='/decks'>Decks</router-link>
        <router-link class='col s6 center-align' to='/counters'>Counters</router-link>
      </ul>
    </nav>
    
  </div>
</template>
<script>
export default {
  name: 'App',
  components: {

  },
  mounted() {
    let sidenav = document.querySelectorAll('.sidenav')
    window.M.Sidenav.init(sidenav, {})
  },
}
</script>

<style lang="scss">
  html {
    @extend .grey, .darken-1;

    -webkit-tap-highlight-color: transparent;
    ::-moz-selection { background:transparent; }
    ::selection { background:transparent; }
    ::-webkit-scrollbar { display: none; }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  body {
    height: 100vmax;
  }

  .navbar-fixed { 
    nav {
      @extend .green, .darken-4;

      i {
        cursor: pointer;

        &.right {
          margin-right: 15px;
        }
      }

      .brand-logo {
        left: 10px !important;
        transform: unset !important;
      }

      a.home {
        @extend .sidenav-trigger;
        margin-left: 10px;

      }
    }
  }

  .sidenav li > a { 
    @extend .black-text;
  }

  .container {
    margin-top: 20px;
    margin-bottom: 56px;
  }



  #toast-container {
    .toast {
      margin-top: 0px;
      min-height: 56px;

      button.toast-action {
        @extend .green-text;
      }
    }
  }

  .card {
    .card-action {
      a.right {
        margin-right: 0px !important;
      }

      a {
        @extend .green-text, .text-darken-4;
      }
    }
  }

  nav.footer {
    @extend .green, .darken-3;
    position: fixed;
    bottom: 0;
    left: 0;
    text-transform: uppercase;

    i.material-icons {
      height: 64px;
      line-height: 60px;
    }

    a {
      @extend .white-text;
      font-weight: bold;
      letter-spacing: 0.075rem;
      &.router-link-exact-active {
        @extend .green, .darken-4; 
      }
    }

  }

  .justify {
    text-align: justify;
  }

  ul.sidenav {
    width: 150px;
    margin-top: 64px;

    li > a {
      letter-spacing: 0.075rem;
      font-weight: bold;
      text-transform: uppercase;
      padding: 0 16px;

      &.router-link-exact-active {
        @extend .green, .darken-3, .white-text;
      }
    }
  }

  #main {
    // padding-bottom: 56px;
  }


  @media #{$small-and-down} {
    // styles for small screens and down
  }
  @media #{$medium-and-up} {
    #main {
      // margin-left: 150px;
    }

    // ul.sidenav {
    //   transform: translateX(0%) !important;
    // }
    // styles for medium screens and larger

  }
  @media #{$medium-and-down} {
    // styles for medium screens and down
  }
  @media #{$large-and-up} {
    // styles for large screens and up
  }
  @media #{$extra-large-and-up} {
    // styles for extra large screens and up
  }


</style>
